<template>
  <div class="home">
    Settings
  </div>
</template>

<script>
export default {
  name: 'Settings',
};
</script>
